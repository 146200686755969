import React, { memo, useState, useEffect, useRef } from 'react'
import { io } from 'socket.io-client'
import { Popup } from 'react-vant'
import { useHistory } from 'react-router-dom'
import './index.scss'

const socket = io("https://api.wanghq.pro", {
    transports: ['websocket'], // 仅使用WebSocket传输协议
    extraHeaders: {
        'Access-Control-Allow-Origin': '*', // 设置跨域请求头
    }
})

export default memo(function Pop_up_audio_call() {

    const history = useHistory()
    const [telephone, setTelephone] = useState(false)
    const [room, setRoom] = useState(123)
    const [friendsData, setFriendsData] = useState()
    const [audioVideoCall, setAudioVideoCall] = useState()
    const [friendsInfo, setFriendsInfo] = useState(false)

    //创建socket.io连接
    socket.emit("joinRoom", { room })
    socket.on("connect", () => {
        console.log("连接成功-----------", socket.id);
    })
    socket.on('callRemote', callRemote)
    socket.on("refuseCall", refuseCall)
    socket.on("hangUp", () => {
        setTelephone(false)
    })

    //出现视频通话弹窗
    function callRemote({ type, friendsInfo }) {
        if (friendsInfo) {
            setFriendsInfo(friendsInfo)
        }
        setAudioVideoCall(type)
        setRoom(123)
        setFriendsData(true)
        setTelephone(true)
    }
    //对方已经取消通话
    function refuseCall() {

        setTelephone(false)
    }
    //拒绝通话
    function refuse() {
        socket.emit("refuseCall", { room })
        setTelephone(false)
    }
    //同意视频通话
    function agreeWith() {
        history.push({ pathname: '/CommunicateVideo', state: { type: audioVideoCall ? "video" : "audio", flag: false, agreeFlags: true } })
    }
    //进入视频通话，暂且没有同意或拒绝
    function enterVideo(e) {
        if (['friendImg', 'friend', 'tool'].includes(e.target.className)) {
            history.push({ pathname: '/CommunicateVideo', state: { friendsInfo, type: audioVideoCall ? "video" : "audio", flag: false, agreeFlags: false } })
        }
    }
    return (
        <div>
            <Popup
                visible={telephone}
                className='Popup'
                position='top'
                overlay={false}
            >
                {friendsData && <div className='telephone' onClick={enterVideo}>
                    <div className='friend'>
                        <img className='friendImg' src={"https://api.wanghq.pro" + friendsInfo.avatar} />
                        <div className='friendImg'>
                            <p className='friendImg'>{friendsInfo.nickname}</p>
                            <p className='friendImg'>{!audioVideoCall ? '邀请您进行语音通话' : '邀请您进行视频通话'}</p>
                        </div>
                    </div>
                    <div className='tool'>
                        <div onClick={refuse}>
                            <svg t="1700894263804" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4497" width="30" height="30"><path d="M941.554162 541.972757c-18.874811 54.963892-58.008216 70.739027-119.752648 62.297946-72.648649-9.963243-133.258378-29.336216-123.987028-92.54746 0 0 10.904216-68.082162-167.271783-72.399567-207.955027-4.28973-205.159784 76.329514-205.159784 76.329513-5.645838 74.143135-57.344 78.543568-131.431784 89.779892-74.004757 11.236324-117.483243-6.642162-120.389189-87.731892-5.645838-156.284541 283.952432-198.545297 409.79373-200.731675 0 0 191.986162-3.874595 281.793729 22.611027l48.432433 13.75481c67.251892 21.863784 126.090378 61.993514 136.192 119.448217 0 0 6.503784 26.181189-8.219676 69.189189z" fill="#ffffff" p-id="4498"></path></svg>
                        </div>
                        <div onClick={agreeWith}>
                            {
                                !audioVideoCall ? <svg t="1701091163431" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2823" width="20" height="20"><path d="M435.111437 592.945977c-100.246262-100.246262-198.359626-217.555718-149.302944-264.4795 66.119875-66.119875 123.708154-106.64496 6.398697-251.682106C174.897734-70.385674 95.980464 42.657984 31.993488 104.512061-42.657984 179.163533 27.72769 454.307529 300.738787 729.451526c270.878198 270.878198 546.022195 341.263872 622.806566 266.6124 61.854077-63.986976 174.897734-142.904246 29.860589-258.080803-145.037146-117.309456-185.56223-59.721178-251.682105 6.398698-46.923782 44.790883-164.233238-51.189581-266.6124-151.435844z m0 0" p-id="2824" fill="#ffffff"></path></svg>
                                    :
                                    <svg t="1700895626769" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="21249" width="25" height="25"><path d="M658.103 735.569c0 26.458-20.723 47.99-46.187 47.99h-492.66c-25.464 0-46.187-21.532-46.187-47.99V319.647c0-26.459 20.723-47.99 46.187-47.99h492.66c25.464 0 46.187 21.531 46.187 47.99V735.57z m206.611-420.756l-133.483 86.308v252.926l132.582 85.64c1.423 1.227 9.153 7.307 21.183 7.307 16.026 0 29.058-13.915 29.058-31.025V339.245c0-17.11-13.033-31.024-29.058-31.024-12.03 0-19.759 6.08-20.282 6.592z" p-id="21250" fill="#fff"></path></svg>
                            }

                        </div>
                    </div>
                </div>}
            </Popup>
        </div>
    )
})
